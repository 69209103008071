<template>
  <v-row>
    <v-subheader>Minha Conta > Dados do Plano</v-subheader>
    <v-card width="100%">
      <base-header-information
        :title="headerTitle"
        :description="currentHeaderDescription"
      >
      </base-header-information>

      <v-col
        class="d-flex text-center justify-center mt-10"
        v-if="loadingScreen"
      >
        <v-progress-circular
          :size="50"
          color="#0288D1"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col v-else class="pa-0 ma-0 pb-8">
        <div
          class="d-flex flex-row justify-center align-start text-center pa-8 "
        >
          <v-row justify="space-around">
            <v-col
              class="data-plan-col py-7 px-4 elevation-4 mb-6"
              :class="$vuetify.breakpoint.xsOnly ? 'd-block' : ''"
              cols="11"
              md="5"
              lg="5"
              sm="10"
            >
              <div class="d-flex flex-column align-start">
                <span class="headline font-weight-medium"
                  >Plano {{ classificationType | translate }}</span
                >
              </div>
              <div class="d-flex flex-column align-center justify-center mt-3">
                <p class="headline font-weight-black">
                  {{ value }}
                  <span class="subtitle-2 font-weight-medium">
                    /{{ cycleType | translate }}
                  </span>
                </p>
                <v-btn color="#024059" dark @click="openDialog()">
                  {{ isChangePlan ? 'Informações' : 'Alterar plano' }}</v-btn
                >
              </div>
            </v-col>
            <v-col
              class="d-flex flex-column align-start data-plan-col elevation-4 mb-6 py-7 px-4"
              cols="11"
              md="4"
              lg="4"
              sm="10"
            >
              <div class="d-flex flex-column align-start">
                <span class="title font-weight-medium"
                  >Detalhes do Pagamento</span
                >
                <span
                  class="subtitle-1 text--secondary font-weight-bold ma-0 pa-0"
                  >{{ paymentType }}</span
                >
              </div>
              <v-btn
                class="mt-2"
                color="#024059"
                outlined
                :to="{ name: 'PaymentForm' }"
                >Alterar Pagamento</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <!-- servicos adicionais -->
        <div class="d-flex flex-column justify-center  aditional-service-list">
          <p class="headline font-weight-medium">Serviços disponíveis</p>
          <template>
            <v-data-table
              :headers="headers"
              :items="listActivities"
              :expanded.sync="expanded"
              show-expand
              class="elevation-1"
              :options="pagination"
              :footer-props="{
                itemsPerPageOptions: [5, 10],
                itemsPerPageText: 'Linhas por página',
              }"
              loading-text="Carregando informações"
              :loading="loading"
              :singleExpand="true"
              sort-by="name"
              :sort-desc="false"
            >
              <template v-slot:item.name="{ item }">
                <span class="font-weight-medium">
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:item.limit="{ item }">
                {{ !item.rule.limitDemarcation ? '--' : limitText(item.rule) }}
              </template>
              <template v-slot:item.requestCount="{ item }">
                {{ item.rule.requestCount ? item.rule.requestCount : '--' }}
              </template>
              <template v-slot:item.correctValue="{ item }">
                {{
                  !item.rule.correctValue != 0 ? '--' : currentValue(item.rule)
                }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="my-3" style="white-space: pre-line;">
                    {{ item.description }}
                  </div>
                </td>
              </template>
            </v-data-table>
          </template>
        </div>
      </v-col>
    </v-card>
    <changePlanPending
      v-model="openDialogPlanPading"
      v-if="openDialogPlanPading"
      :dataPlanChangePending="planToChange"
      :cancelation="true"
    ></changePlanPending>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
// importacao dos helpers registrados, para acessar os methodos e getter do payment info e pegar o id do usuario
import { paymentMethods, paymentComputed, authComputed } from '@state/helpers'

export default {
  name: 'plan-data',
  components: {
    changePlanPending: () =>
      import('@src/components/allPlans/changePlanPending.vue'),
  },
  data() {
    return {
      openDialogPlanPading: false,
      loadingScreen: false,
      headerTitle: 'Dados do Plano',
      loading: false,
      pagination: {
        itemsPerPage: 8,
      },
      expanded: [],
      headers: [
        {
          text: 'Nome do serviço',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Limite', value: 'limit', sortable: false },
        { text: 'Valor', value: 'correctValue', sortable: false },
        {
          text: 'Quantidade contratada',
          value: 'requestCount',
          sortable: false,
        },
        { text: 'Descrição', value: 'data-table-expand', sortable: false },
      ],
    }
  },
  computed: {
    // percorrer os array dos computeds registrados no helpers
    ...authComputed,

    ...paymentComputed,

    ...mapGetters({
      planToChange: 'planChangeNotification/planToChange',
    }),

    currentHeaderDescription() {
      return `Consulte os serviços disponíveis para o seu plano!`
    },

    listActivities() {
      return this.paymentListData?.plan?.adminActivities?.filter((item) => {
        return !item.rule.isBlocked
      })
    },

    value() {
      return this.paymentListData?.plan?.valueRs
    },

    jurudicType() {
      return this.paymentListData?.plan?.type
    },

    cycleType() {
      return this.paymentListData?.plan?.cycleTypr
    },

    classificationType() {
      return this.paymentListData?.plan?.classificationType
    },

    paymentType() {
      return this.paymentListData?.idPaymentsType == 1
        ? 'Cartão de Crédito'
        : 'Boleto'
    },

    isChangePlan() {
      return this.planToChange.hasOwnProperty('id')
    },
  },

  methods: {
    // percorrer os array dos methods registrados no helpers
    ...paymentMethods,

    openDialog() {
      if (this.planToChange.hasOwnProperty('id')) {
        this.openDialogPlanPading = true
      } else {
        this.$router.push({
          path: '/comparativo-planos',
        })
      }
    },

    limitText(rule) {
      let limitType = rule.limitType === 'month' ? 'por mês' : 'por ano'
      switch (rule.limitDemarcation) {
        case 'unlimited':
          return 'Ilimitado'
          break
        case 'fixed':
          return `Até ${rule.limit} contratações ${limitType} sem cobrança`
          break

        case 'not applicable':
          return `--`
          break

        default:
          break
      }
    },

    currentValue(rule) {
      return this.$options.filters.currencyFromCents(rule.correctValue)
    },
  },

  async mounted() {
    this.loadingScreen = true
    await this.getPaymentData()
    this.loadingScreen = false
  },
}
</script>

<style lang="scss">
.data-plan-col {
  display: flex;
  align-items: initial;
  justify-content: space-between;
  box-shadow: none;
}

.aditional-service-list {
  margin: 0px 6vw 0px 6vw;
}
</style>
